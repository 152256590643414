<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ul class="nav nav-pills" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" data-bs-toggle="pill" href="#client-information" role="tab" aria-selected="true">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Client Information</div>
                  </div>
                </a>
              </li>
              <li v-if="client.id" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#client-jobs" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Jobs ({{jobsCounter}})</div>
                  </div>
                </a>
              </li>
              <li v-if="$hasRole('Admin', 'Sales')" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#client-costsheet" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Costsheet</div>
                  </div>
                </a>
              </li>
              <li v-if="$hasRole('Admin', 'Sales')" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#client-maintenance" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Maintenance Report</div>
                  </div>
                </a>
              </li>
              <li v-if="client.id" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#client-sites" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Sites</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade active show" id="client-information" role="tabpanel">
        <div class="row">

          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                  <secure-image v-if="client.logo" :id="client.name + '-logo'" :endpoint="`/v1/client/${client.id}/logo`" width="110"></secure-image>
                  <div class="mt-3">
                    <h4>{{client.name}}</h4>
                  </div>
                </div>
                <hr class="my-4">
                <ul class="list-group list-group-flush mb-2">
                  <li v-if="edit" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Name</h6>
                    <input class="form-control" style="width: 75%;" type="text" v-model="client.name">
                  </li>
                  <li v-if="edit" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Logo</h6>
                    <input type="file" accept="image/*" ref="file" id="brandLogo" @change="selectFile" class="form-control">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Street</h6>
                    <span v-if="!edit" class="text-white">{{client.street || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="client.street">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Town</h6>
                    <span v-if="!edit" class="text-white">{{client.town || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="client.town">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">County</h6>
                    <span v-if="!edit" class="text-white">{{client.county || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="client.county">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Postcode</h6>
                    <span v-if="!edit" class="text-white">{{client.postcode || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="client.postcode">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Telephone</h6>
                    <span v-if="!edit" class="text-white"><i :title="client.telephone_verified < 1 ? 'Not Verified' : 'Verified'" :class="{'bx bx-x text-danger': client.telephone_verified === 0, 'bx bx-check text-success': client.telephone_verified === 1}"></i> {{client.telephone || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="tel" v-model="client.telephone">
                  </li>
                </ul>

                <submit-button :background="'btn btn-sm btn-primary ms-2'" style="float: right;" :clicked='updating' @click="saveChanges" :text="'Save Changes'"></submit-button>
                <button class="btn btn-sm" :class="{'btn-light': !edit, 'btn-danger': edit}" @click="edit = !edit" style="float: right;">{{ edit ? 'Stop Editing' : 'Edit' }}</button>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Monitoring Services <button class="btn btn-sm btn-success float-end" data-bs-toggle="modal" data-bs-target="#createServiceModal">New Service</button></h5>
                <table id="example" class="table table-hover" style="width:100%">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>All Sites?</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="service in services" :key="service.id">
                    <td>{{service.id}}</td>
                    <td>{{service.name}}</td>
                    <td> {{service.all_sites == 1 ? '✓' : '×'}} </td>
                    <td>
                      <router-link class="btn btn-sm btn-primary" :to="{name: 'admin.clients.services.view', props: {id: service.id, client_id: id}, params: {id: service.id, client_id: id}}">Manage</router-link>
                      <!--                    <button class="btn btn-sm btn-primary" @click="$router.push(`/p/clients/${id}/services/${service.id}`); $router.go(0)">Manage</button>-->
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card mg-t-20">
              <div class="card-body">
                <h5 class="card-title">Maintenance Schedule</h5>

                <button class="btn btn-sm btn-primary float-end" @click="downloadSchedule">Download Schedule</button>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Monitoring Rules <router-link :to="{name: 'admin.clients.rules.view', props: {client_id: id}, params: {client_id: id}}" class="btn btn-sm btn-primary float-end">Manage Rules</router-link></h5>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div v-if="client.id" class="tab-pane fade" id="client-jobs" role="tabpanel">

        <div class="row mt-2">
          <div class="col-12">
            <AdminJobs v-if="client.name" :lockClientID="client.id" @jobsLoaded="updateJobsCounter"></AdminJobs>
          </div>
        </div>

      </div>
      <div  v-if="client.id && $hasRole('Admin', 'Sales')" class="tab-pane fade" id="client-costsheet" role="tabpanel">
        <client-costsheet :client_id="client.id"></client-costsheet>
      </div>
      <div v-if="$hasRole('Admin', 'Sales')" class="tab-pane fade" id="client-maintenance" role="tabpanel">

        <div class="row mt-2">

          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Maintenance Report</h5>

                <div class="alert border-0 border-info border-start border-5 alert-dismissible fade show">
                  <div class="text-white">Please use the form below to generate a maintenance report</div>
                </div>

                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="maintenanceReportPeriod" class="form-label">Warranty Period</label>
                    <select id="maintenanceReportPeriod" v-model="report.period" :value="report.period" style=" width: 100%;" class="form-select">
                      <option selected="" value="">- Warranty Period -</option>
                      <option value="365">1 Year</option>
                      <option value="730">2 Years</option>
                      <option value="1095">3 Years</option>
                      <option value="1460">4 Years</option>
                      <option value="1825">5 Years</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="maintenanceReportMargin" class="form-label">Margin (%)</label>
                    <input id="maintenanceReportMargin" name="maintenanceReportMargin" class="form-control form-control-sm" v-model="report.margin" type="number" min="1" max="100" step="1">
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <SubmitButton :clicked="generating" class="btn btn-primary float-end" @click="generateReport" text="Generate Report"/>
                  </div>
                </div>

              </div>
            </div>

            <div v-if="generating > 0" class="card mt-2d">
              <div class="card-body text-center">
                <div class="spinner-border" role="status"> <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>

            <!-- STATS -->
            <div v-if="report.sites.length > 0" class="row">
              <div class="col-12">
                <div class="card radius-10">
                  <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 row-group g-0">
                    <div class="col">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <h5 class="mb-0">{{sitesOutOfWarranty}}</h5>
                        </div>
                        <div class="progress my-3" style="height:3px;">
                          <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="d-flex align-items-center text-white">
                          <p class="mb-0">Sites out of Warranty</p>
                          <!--              <p class="mb-0 ms-auto">+4.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <h5 class="mb-0">£{{totalCost}}</h5>
                        </div>
                        <div class="progress my-3" style="height:3px;">
                          <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="d-flex align-items-center text-white">
                          <p class="mb-0">Total Cost</p>
                          <!--              <p class="mb-0 ms-auto">+1.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <h5 class="mb-0">£{{totalMonthlyCost}}</h5>
                        </div>
                        <div class="progress my-3" style="height:3px;">
                          <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="d-flex align-items-center text-white">
                          <p class="mb-0">Monthly Cost</p>
                          <!--              <p class="mb-0 ms-auto">+1.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <h5 class="mb-0">{{totalMissingProducts.toLocaleString()}}</h5>
                        </div>
                        <div class="progress my-3" style="height:3px;">
                          <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="d-flex align-items-center text-white">
                          <p class="mb-0">Missing Products</p>
                          <!--              <p class="mb-0 ms-auto">+5.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                        </div>
                      </div>
                    </div>
                  </div><!--end row-->
                </div>
              </div>

            </div>

            <div v-if="report.sites.length > 0" class="card mt-2d">
              <div class="card-body">

                <div class="row">
                  <div class="col-12">
                    <div class="form-group mg-b-10" style="float: right;">
                      <input class="form-check-input" type="checkbox" id="includeCostInExport" v-model="report.includeCostInExport">
                      <label class="form-check-label" for="statusColumn">Include cost?</label>
                    </div>
                  </div>
                </div>

                <button class="btn float-end text-white tx-uppercase tx-bold mg-b-10" @click="saveMaintenanceReport" style="background-color: #1D6F42;">Export CSV <i class="fas fa-file-csv mg-l-10 mg-r-5"></i></button>

                <table class="table">
                  <thead>
                    <tr>
                      <th>Site</th>
                      <th>Install Date</th>
                      <th>Maintenance Cost</th>
                      <th>Missing Products</th>
                      <th>Site ID</th>
                      <th v-for="brand in report.brands" :key="'brand-' + brand">{{brand}} ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :class="{'bg-warning text-dark': site.missing_costs > 0}" v-for="site in report.sites" :key="'site-' + site.name">
                      <td>{{site.name}}</td>
                      <td>{{site.install_date}}</td>
                      <td class="clickable_link" @click="loadSiteBreakdown(site)">£{{parseFloat(site.maintenance_cost || 0).toFixed(2)}}</td>
                      <td>{{parseFloat(site.missing_costs || 0).toFixed(0)}}</td>
                      <td>{{site.client_assigned_id || "-"}}</td>
                      <td v-for="brand in report.brands" :key="'brand-' + brand">{{site.formattedBrands[brand]?.brand_site_id || '-'}}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>

        </div>

      </div>
      <div v-if="client.id" class="tab-pane fade" id="client-sites" role="tabpanel">
        <client-sites :client_id="client.id"></client-sites>
      </div>
    </div>
    <!-- MODALS -->

    <div class="modal fade" id="createServiceModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Monitoring Service</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
<!--              <div class="col-md-12 mb-2">-->
<!--                <label for="newServiceType" class="form-label">Monitor Type <span class="text-danger">*</span></label>-->
<!--                <Multiselect v-model="newService.monitor_type" id="newServiceType" :searchable="true" :options="['Ping', 'API']"></Multiselect>-->
<!--              </div>-->
<!--              <div class="col-md-12 mb-2">-->
<!--                <label for="newServiceProductType" class="form-label">Product Type <span class="text-danger">*</span></label>-->
<!--                <Multiselect v-model="newService.product_type" id="newServiceProductType" :searchable="true" :options="types"></Multiselect>-->
<!--              </div>-->
              <div class="col-md-12 mb-2">
                <label for="newServiceName" class="form-label">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="newServiceName" v-model="newService.name">
              </div>
              <div class="col-md-12 mb-2">
                <div class="form-check">
                  <input class="form-check-input" v-model="newService.all_sites" type="checkbox" value="" id="flexCheckChecked" checked="">
                  <label class="form-check-label" for="flexCheckChecked">Monitor all sites? <span class="text-danger">*</span></label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateServiceModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createService" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="viewSiteBreakdown" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Site Maintenance Breakdown</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p class="text-center fw-bold text-white">{{report.breakdownSite?.name}}</p>
            <table v-if="report.breakdown.length > 0" class="table">
              <thead>
              <tr>
                <th>Product</th>
                <th>Cost</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="entry in report.breakdown" :key="'entry-' + entry.name">
                <td>{{entry.name}}</td>
                <td>£{{(parseFloat(entry.cost || 0) / parseInt(entry.quantity || 1)).toFixed(2)}}</td>
                <td>x{{entry.quantity}}</td>
                <td>£{{parseFloat(entry.cost || 0).toFixed(2)}}</td>
              </tr>
              </tbody>
            </table>

            <p class="text-center text-white">Total: £{{parseFloat(report.breakdownSite?.maintenance_cost || 0).toFixed(2)}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeViewSiteBreakdownModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import SecureImage from '../../../components/SecureImage.vue'
import SubmitButton from '../../../components/SubmitButton.vue';
import AdminJobs from '../jobs/Jobs';
import * as XLSX from 'xlsx/xlsx.mjs';
import saveAs from 'file-saver';
import {DateTime} from 'luxon';
import ClientCostsheet from "./ClientCostsheet";
import ClientSites from "./ClientSites";
export default {
  components: {ClientCostsheet, AdminJobs, SecureImage, SubmitButton, ClientSites },
  name: 'admin.clients.view',
  props: ['id'],
  data(){
    return {
      client: {
        name: "",
        street: "",
        town: "",
        county: "",
        postcode: "",
        telephone: "",
        telephon_verified: 0
      },
      logo: null,
      edit: false,
      updating: false,
      services: [],
      newService: {
        name: null,
        all_sites: true
      },
      creating: false,
      types: [],
      jobsCounter: 0,
      generating: false,
      report: {
        period: 1096,
        margin: 7,
        sites: [],
        brands: [],
        breakdownSite: null,
        breakdown: [],
        breakdownLoading: false,
        includeCostInExport: false
      }
    }
  },
  mounted(){
    this.loadClient()
    this.loadTypes();
  },
  methods: {
    updateJobsCounter(total){
        this.jobsCounter = total;
    },
    loadClient(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/${this.id}`)
      .then(response => {
        this.client = response.data.client;
        this.loadServices();
      })
      .catch(error => {
        this.$error("Failed to load client.", error);
      })
    },
    loadServices(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/${this.id}/monitoring`)
      .then(response => {
        if(response.data.success){
          this.services = response.data.services;
        }
      })
      .catch(error => {
        this.$error("Failed to load services", error);
      })
    },
    loadTypes(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/product/types`)
      .then(response => {
        this.types = response.data.types
      })
      .catch(error => {
        this.$error("Failed to load types", error);
      })
    },
    selectFile(){
      this.logo = this.$refs.file.files[0];
      console.log(this.logo);
    },
    saveChanges(){
      this.updating = true;
      let data = new FormData();
      data.append('name', this.client.name);
      if(this.logo !== null){
        data.append('logo', this.logo, this.logo_filename);
      }
      data.append('street', this.client.street);
      data.append('town', this.client.town);
      data.append('county', this.client.county);
      data.append('postcode', this.client.postcode);
      data.append('telephone', this.client.telephone);
      data.append('telephone_verified', this.client.telephon_verified || 0);
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/client/${this.id}`, data)
      .then(() => {
        this.$success("Updated client");
        this.updating = false;
        this.edit = false;
        this.loadClient();
      })
      .catch(error => {
        this.$error("Failed to update client.", error);
        this.updating = false;
      })
    },
    createService(){
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/client/${this.id}/monitoring`, this.newService)
      .then(response => {
        if(response.data.success){
          this.$success("Created a new monitoring service");
          this.loadServices();
          $('#closeCreateServiceModal').click();
        }
      })
      .catch(error => {
        this.$error("Failed to create a new monitoring service", error);
      })
      .finally(() => {
        this.creating = false;
      })
    },
    generateReport(){
      this.generating = true;
      this.report.sites = [];
      this.report.brands = [];

      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/${this.client.id}/maintenance?period=${this.report.period}&margin=${this.report.margin}`)
      .then(response => {
        this.report.sites = response.data.sites;
        this.report.brands = response.data.brands;
        this.report.sites.forEach((s) => {
          s.formattedBrands = {};
          s.install_date = DateTime.fromSeconds(s.install_date).toFormat('dd/MM/yyyy');
          this.report.brands.forEach((b) => {
            s.formattedBrands[b] = s.brands.filter((sb) => {
              return sb.name === b;
            })[0] || null;
          })
        })
        this.$success("Loaded report");
      })
      .catch(error => {
        this.$error("Failed to load report", error);
      })
      .finally(() => {
        this.generating = false;
      })

    },
    loadSiteBreakdown(site){
      this.report.breakdownLoading = true;
      this.report.breakdownSite = site;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${site.id}/maintenance?margin=${this.report.margin}`)
      .then(response => {
        this.report.breakdown = response.data.breakdown;
        $('#viewSiteBreakdown').modal('toggle');
      })
      .catch(error => {
        this.$error("Failed to load site breakdown!", error);
      })
      .finally(() => {
        this.report.breakdownLoading = false;
      })
    },
    saveMaintenanceReport(){
      let data = [];
      this.report.sites.forEach((s) => {
        let brands = {};
        this.report.brands.forEach((b) => {
          brands[b] = s.formattedBrands[b]?.brand_site_id;
        });
        console.log(brands);
        if(this.report.includeCostInExport){
          data.push({
            "Site": s.name,
            "Install Date": s.install_date,
            "Site ID": s.client_assigned_id || "",
            "Maintenance Cost": s.maintenance_cost,
            ...brands
          })
        }else{
          data.push({
            "Site": s.name,
            "Install Date": s.install_date,
            "Site ID": s.client_assigned_id || "",
            ...brands
          });
        }
      })

      let filename = 'Link Maintence Report - ' + this.client.name + ' ' + DateTime.local().toFormat('yyyy-MM-dd');
      let book = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(book, ws, 'Maintenance Report');
      var wopts = { bookType:'xlsx', bookSST:false, type: 'array'};
      var wbout = XLSX.write(book,wopts);
      /* the saveAs call downloads a file on the local machine */
      saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${filename}.xlsx`);
    },
    downloadSchedule(){

      axios.get(`${process.env.VUE_APP_API_URL}/v1/reports/maintenancedates?client_id=${this.client.id}`)
      .then(response => {
        let data = response.data?.maintenance || [];
        let formatted = [];

        data = data.sort((a,b) => {
          return a.next_due - b.next_due;
        });

        data.forEach((entry) => {
          formatted.push({
            "Site": entry.name,
            "Contract Type": entry.contract_type,
            "Maintenance Type": entry.type,
            "Next Due": DateTime.fromSeconds(entry.next_due).toFormat('dd/MM/yyyy'),
          })
        });

        // Generate CSV file
        let filename = 'Link Maintenance Schedule - ' + this.client.name + ' ' + DateTime.local().toFormat('yyyy-MM-dd');
        let book = XLSX.utils.book_new();
        let ws = XLSX.utils.json_to_sheet(data);

        XLSX.utils.book_append_sheet(book, ws, 'Maintenance Schedule');
        var wopts = { bookType:'xlsx', bookSST:false, type: 'array'};
        var wbout = XLSX.write(book,wopts);
        /* the saveAs call downloads a file on the local machine */
        saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${filename}.xlsx`);

      })

    }
  },
  computed: {
    logo_filename: function(){
        if(this.logo == null){
            return "Select File";
        }
        if(this.logo == null){
            return "File Selected";
        }
        return this.logo.name;
    },
    sitesOutOfWarranty: function(){
      if(this.report.sites.length < 1) return 0;
      return this.report.sites.length;
    },
    totalCost: function(){
      if(this.report.sites.length < 1) return 0;
      let cost = 0;
      this.report.sites.forEach((s) => {
        cost += parseFloat(s.maintenance_cost || 0);
      });
      return parseFloat(cost).toLocaleString();
    },
    totalMonthlyCost: function(){
      if(this.report.sites.length < 1) return 0;
      let cost = 0;
      this.report.sites.forEach((s) => {
        cost += parseFloat(s.maintenance_cost || 0);
      });
      return parseFloat(cost / 12).toLocaleString();
    },
    totalMissingProducts: function(){
      if(this.report.sites.length < 1) return 0;
      let count = 0;
      this.report.sites.forEach((s) => {
        count += s.missing_costs
      });
      return count.toLocaleString();
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
 .clickable_link {
   font-weight: bold;
   cursor: pointer;
 }
 .clickable_link:hover {
   text-decoration: underline;
 }
</style>