<template>
  <div>

    <div class="card">
      <div class="card-body">

        <div class="position-relative wd-50">
          <input v-on:keyup="loadSites(1)" type="text" class="form-control ps-5" v-model="filters.search"
            placeholder="Search Site..."> <span class="position-absolute top-50 product-show translate-middle-y"><i
              class="bx bx-search"></i></span>
        </div>

        <br />

        <button type="button" class="btn btn-sm btn-light ms-2" style="float: right;" title="Manage Columns"
          data-bs-toggle="modal" data-bs-target="#manageColumnsModal">
          <i class="bx bxs-grid me-0"></i>
        </button>
        <button type="button" style="float: right" @click="$refs.newsiteform.show()"
          class="btn btn-primary px-5 ms-3">New Site</button>
        <pagination @search="loadSites" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
            <tr>
              <th>Status</th>
              <th v-show="columns.brand">Brands</th>
              <th v-show="columns.client">Client</th>
              <th v-show="columns.name">Name</th>
              <th v-show="columns.street">Street</th>
              <th v-show="columns.town">Town</th>
              <th v-show="columns.county">County</th>
              <th v-show="columns.postcode">Postcode</th>
              <th v-show="columns.latlong">Latitude, Longitude</th>
              <th v-show="columns.subnet">Subnet</th>
              <th v-show="columns.gateway">Gateway</th>
              <th v-show="columns.jobs"># Jobs</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="site in sites" :key="site.id" style="cursor: pointer;" @click="openSitePage(site.id)">
              <td>
                <i data-bs-toggle="tooltip" data-bs-placement="top" title=""
                  :data-bs-original-title="site.failedDevices + ' devices offline'" :class="{
                    'text-success': site.failedDevices === 0,
                    'text-danger': site.failedDevices > 0
                  }" class='bx bxs-circle'></i>
              </td>
              <td v-if="columns.brand">
                <div v-for="brand in site.brands" :key="brand.name + '-logo'" style="display: inline-block;">
                  <secure-image v-if="brand.logo" :id="brand.name + '-logo'" :endpoint="`/v1/brands/${brand.id}/logo`"
                    style="height: 2rem;"></secure-image>
                </div>
              </td>
              <td v-show="columns.client"><secure-image v-if="site.client_logo" style="height: 1rem;"
                  :id="site.client_name + '-logo'" :endpoint="`/v1/client/${site.client_id}/logo`"></secure-image><span
                  v-else>{{ site.client_name }}</span></td>
              <td v-show="columns.name">{{ site.name }}</td>
              <td v-show="columns.street">{{ site.street || "-" }}</td>
              <td v-show="columns.town">{{ site.town || "-" }}</td>
              <td v-show="columns.county">{{ site.county || "-" }}</td>
              <td v-show="columns.postcode">{{ site.postcode || "-" }}</td>
              <td v-show="columns.latlong">{{ site.latlong || "-" }}</td>
              <td v-show="columns.subnet">{{ site.range_and_subnet || "-" }}</td>
              <td v-show="columns.gateway">{{ site.gateway || "-" }}</td>
              <td v-show="columns.jobs">{{ site.jobCount || "0" }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th v-show="columns.brand"></th>
              <th v-show="columns.client">Client</th>
              <th v-show="columns.name">Name</th>
              <th v-show="columns.street">Street</th>
              <th v-show="columns.town">Town</th>
              <th v-show="columns.county">County</th>
              <th v-show="columns.postcode">Postcode</th>
              <th v-show="columns.latlong">Latitude, Longitude</th>
              <th v-show="columns.subnet">Subnet</th>
              <th v-show="columns.gateway">Gateway</th>
              <th v-show="columns.jobs"># Jobs</th>
            </tr>
          </tfoot>
        </table>

        <pagination @search="loadSites" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <new-site-form id="sitesNewSiteForm" @created="loadSites(pagination.page)" ref="newsiteform"></new-site-form>

    <div class="modal fade" id="manageColumnsModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Columns</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please note these settings will be saved locally in your browser. If you clear your browser settings
              these settings may reset.</p>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="brandColumn" @change="updateColumnSettings"
                v-model="columns.brand">
              <label class="form-check-label" for="brandColumn">Brand</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="clientColumn" @change="updateColumnSettings"
                v-model="columns.client">
              <label class="form-check-label" for="clientColumn">Client</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="nameColumn" @change="updateColumnSettings"
                v-model="columns.name">
              <label class="form-check-label" for="nameColumn">Name</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="streetColumn" @change="updateColumnSettings"
                v-model="columns.street">
              <label class="form-check-label" for="streetColumn">Street</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="townColumn" @change="updateColumnSettings"
                v-model="columns.town">
              <label class="form-check-label" for="townColumn">Town</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="countyColumn" @change="updateColumnSettings"
                v-model="columns.county">
              <label class="form-check-label" for="countyColumn">County</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="postcodeColumn" @change="updateColumnSettings"
                v-model="columns.postcode">
              <label class="form-check-label" for="postcodeColumn">Postcode</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="latlongColumn" @change="updateColumnSettings"
                v-model="columns.latlong">
              <label class="form-check-label" for="latlongColumn">Latitude, Longitude</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="subnetColumn" @change="updateColumnSettings"
                v-model="columns.subnet">
              <label class="form-check-label" for="subnetColumn">Subnet</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="gatewayColumn" @change="updateColumnSettings"
                v-model="columns.gateway">
              <label class="form-check-label" for="gatewayColumn">Gateway</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="jobsColumn" @change="updateColumnSettings"
                v-model="columns.jobs">
              <label class="form-check-label" for="jobsColumn"># Jobs</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
              id='closeManageColumnsModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../components/Pagination.vue';
import SecureImage from '../../../components/SecureImage.vue';
import NewSiteForm from "../sites/NewSiteForm";
export default {
  components: { NewSiteForm, Pagination, SecureImage },
  name: 'admin.clients.sites',
  props: {
    client_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      sites: [],
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      },
      filters: {
        search: ""
      },
      columns: {
        brand: true,
        client: true,
        name: true,
        street: true,
        town: true,
        county: true,
        postcode: true,
        subnet: true,
        gateway: true,
        jobs: true
      }
    }
  },
  mounted() {
    this.filters.search = localStorage.getItem('link::clients::sites::search') || "";
    this.loadSites()
    this.loadColumnSettings();
  },
  methods: {
    openSitePage(id) {
      const routeData = this.$router.resolve({ name: 'admin.sites.view', params: { id: id } });
      window.open(routeData.href, '_blank');
    },
    loadSites(page = 1) {
      localStorage.setItem('link::clients::sites::search', this.filters.search);
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/${this.client_id}/sites?page=${page}&search=${this.filters.search}`)
        .then(response => {
          this.sites = response.data.sites;
          this.sites.forEach((s) => {
            if (s.latitude && s.longitude) {
              s.latlong = `${s.latitude}, ${s.longitude}`;
            }
          });
          this.pagination = response.data.pagination;
          this.$nextTick(() => {
            $('[data-bs-toggle="tooltip"]').tooltip();
          })
        })
        .catch(error => {
          this.$error('Failed to load sites.', error);
        });
    },
    updateColumnSettings() {
      localStorage.setItem('link::clients::sites::columns', JSON.stringify(this.columns));
    },
    loadColumnSettings() {
      let savedColumns = localStorage.getItem('link::clients::sites::columns');
      console.log(savedColumns);
      if (savedColumns !== undefined && savedColumns !== null && savedColumns !== 'null' && savedColumns !== '') {
        console.log('Updating columns');
        this.columns = JSON.parse(savedColumns);
      }
    },

  }
}
</script>