<template>
  <div class="row mt-2">

    <div v-if="costs.length > 0" class="row">
      <div class="col-12">
        <div class="card radius-10">
          <div class="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-group g-0">
            <div class="col">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">{{costs.length}}</h5>
                </div>
                <div class="progress my-3" style="height:3px;">
                  <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center text-white">
                  <p class="mb-0">Products</p>
                  <!--              <p class="mb-0 ms-auto">+4.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">{{totalMissingProducts.toLocaleString()}}</h5>
                </div>
                <div class="progress my-3" style="height:3px;">
                  <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center text-white">
                  <p class="mb-0">Missing Products</p>
                  <!--              <p class="mb-0 ms-auto">+5.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                </div>
              </div>
            </div>
          </div><!--end row-->
        </div>
      </div>

    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Cost Sheet</h5>

          <button class="btn btn-success float-end" @click="save">Save</button>

          <table class="table">
            <thead>
            <tr>
              <th>Product</th>
              <th style="max-width: 100px;">Purchase Cost</th>
              <th>Margin</th>
              <th>Margin Cost</th>
              <th>Monthly Cost</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              <tr :class="{'bg-warning text-dark': !cost.margin}" v-for="cost in costs" :key="cost.id">
                <td>{{cost.name}}</td>
                <td style="max-width: 100px;">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon2">£</span>
                    <input autocomplete="off" class="form-control form-control-sm" v-model="cost.cost" type="number">
                  </div>
                </td>
                <td style="max-width: 100px;">
                  <div class="input-group">
                    <input autocomplete="off" class="form-control form-control-sm" v-model="cost.margin" type="number" min="0" max="100" step="1">
                    <span class="input-group-text">%</span>
                  </div>
                </td>
                <td>£{{parseFloat((cost.cost || 0) * ((cost.margin || 100) / 100)).toFixed(2)}}</td>
                <td>£{{parseFloat((cost.cost || 0) * ((cost.margin || 100) / 100) / 12).toFixed(2)}}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "ClientCostsheet",
  props: {
    client_id: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      costs: []
    }
  },
  mounted(){
    this.loadCosts();
  },
  methods: {
    loadCosts(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/${this.client_id}/costsheet`)
      .then(response => {
        if(response.data.success){
          response.data.costs = response.data.costs.sort((a,b) => {
            if(a.name < b.name){
              return -1;
            }
            if(a.name > b.name){
              return 1;
            }
            return 0;
          });
          response.data.costs.forEach((c) => {
            c.original_cost = c.cost;
            c.original_margin = c.margin;
          })
          this.costs = response.data.costs;
        }
      })
      .catch(error => {
        this.$error("Failed to load costsheet", error);
      })
    },
    save(){
      let data = [];
      this.costs.forEach((c) => {
        if(c.cost === '') c.cost = null;
        if(c.margin === '') c.margin = null;
        if(c.cost !== c.original_cost || c.margin !== c.original_margin){
          data.push({id: c.id, cost: c.cost, margin: c.margin, cost_id: c.cost_id});
        }
      });
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/client/${this.client_id}/costsheet`, {
        costs: data
      })
      .then(response => {
        if(response.data.success){
          this.$success("Saved changes!");
          this.costs.forEach((c) => {
            c.original_cost = c.cost;
            c.original_margin = c.margin;
          });
        }
      })
      .catch(error => {
        this.$error("Failed to save changes", error);
      });
    }
  },
  computed: {
    totalMissingProducts(){
      let count = 0;
      this.costs.forEach((c) => {
        if(!c.cost) count++;
      });
      return count.toLocaleString();
    }
  }
}
</script>

<style scoped>

</style>